import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getSelectSetForBoolean } from '../utils/formUtils';
import CustomInputSelect from '../components/CustomInputSelect';

import {
  CustomInputContainer,
  CustomInputSubContainer,
  CustomInputLabel,
  CustomInputField,
  CustomInputCheckboxContainer,
  CustomInputCheckbox,
  CustomInputCheckboxCheckmark,
  CustomFileContainer,
  CustomFileSpan,
  FormErrMsg
} from '../styles/styled-components/CustomInput';
import TooltipInfo from './TooltipInfo';
import ButtonIconText from './ButtonIconText';

const CustomInput = ({
  areCustomChildren=false,
  typeOfInput="text",
  checkbox=false,
  children,
  definedWidth,
  isFocus=false,
  isFullWith=false,
  isInputDisabled=false,
  isInvalid,
  isRequired=false,
  isRowElemDirection=false,
  isVisible=true,
  invalidMsg,
  itemData,
  max,
  min,
  name,
  handleBlur,
  handleChange,
  handleChangeCheckboxState,
  handleKeyDownEnter,
  label,
  placeholder,
  value,
  selectOptions,
  extraWidth,
  tooltipInfo
}) => {

  const { t } = useTranslation();

  const setPlaceholder = () => {
    if (placeholder) {
      return placeholder;
    }
    else if (itemData?.default) {
      return t('common.enter_default_value') + itemData.default;
    }
    else {
      return "";
    }
  };
  
  const renderInput = () => {

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
        handleKeyDownEnter(e);
      }
    };

    if (typeOfInput === "select" || typeOfInput === "boolean") {
      if (typeOfInput === "boolean") {
        selectOptions = getSelectSetForBoolean();
      }
      return (
        <>
          <CustomInputSelect
            definedWidth={definedWidth}
            disabled={isInputDisabled || !isVisible}
            id={`${name}-input`}
            invalid={isInvalid}
            options={selectOptions} 
            value={value}
            onChangeFun={(e) => handleChange(e)} 
          />
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }

    if (typeOfInput === "fileModal") {
      return (
        <>
          <CustomFileContainer invalid={isInvalid}>
            <CustomFileSpan fileExist={value ? 1 : 0} id="fileName">
              {value ?
                t('template_definition.added_' + value.resource_type)
                :
                t('files.empty')
              }
            </CustomFileSpan>
            <ButtonIconText
              icon={value ? 'change' : null}
              text={!value ? t('common.add') : null}
              onClick={(e)=>handleChange(e)}
            />
          </CustomFileContainer>
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }
    
    if (typeOfInput === "script") {
      return (
        <>
          <CustomInputField
            id={`${name}-input`}
            invalid={isInvalid}
            name={name}
            placeholder={setPlaceholder()}
            type="textarea"
            value={value || ''}
            onBlur={handleBlur && ((e) => handleBlur(e))}
            onChange={(e) => handleChange(e)}
          />
          {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg>}
        </>
      );
    }

    return (
      <>
        <CustomInputField
          autoFocus={isFocus}
          disabled={isInputDisabled || !isVisible}
          id={`${name}-input`}
          invalid={isInvalid}
          max={max}
          min={min}
          name={name}
          placeholder={setPlaceholder()}
          step={typeOfInput === "number" ? "0.01" : "1"}
          //type={locTypeOfInput === "float" ? "text" : locTypeOfInput}
          type={typeOfInput === "integer" ? "number" : typeOfInput}
          value={value || ''}
          onBlur={handleBlur && ((e) => handleBlur(e))}
          checkbox={+checkbox}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyDown(e, (typeOfInput === "float"))}
        />
        {isInvalid && <FormErrMsg className='mt-0 mb-n2'>{invalidMsg}</FormErrMsg> }
      </>
    );
  };

  return (
    <CustomInputContainer $row_line={+isRowElemDirection} className={isFullWith && "w-100"} $extrawidth={extraWidth ? 1 : 0} $definded_width={definedWidth}>
      <CustomInputLabel for={`${name}-input`}>
        {label}
        {tooltipInfo && <TooltipInfo text={tooltipInfo} elemName={name} />}
        {isRequired && " *"}
      </CustomInputLabel>
      <CustomInputSubContainer className={!checkbox && "flex-column"}>
        {checkbox && (
          <CustomInputCheckboxContainer>
            <CustomInputCheckbox
              type="checkbox"
              name={name}
              checked={!isInputDisabled}
              onChange={(e) => handleChangeCheckboxState(e)}
              disabled={!isVisible}
            />
            <CustomInputCheckboxCheckmark />
          </CustomInputCheckboxContainer>
        )}
        {areCustomChildren 
          ? children
          : renderInput()
        }
      </CustomInputSubContainer>
    </CustomInputContainer>
  );
};

CustomInput.propTypes = {
  areCustomChildren: PropTypes.bool,
  typeOfInput: PropTypes.string,
  children: PropTypes.any,
  checkbox: PropTypes.bool,
  definedWidth: PropTypes.number,
  isFocus: PropTypes.bool,
  isFullWith: PropTypes.bool,
  isInputDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  isRowElemDirection: PropTypes.bool,
  isVisible: PropTypes.bool,
  id: PropTypes.string,
  invalidMsg: PropTypes.string,
  itemData: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleChangeCheckboxState: PropTypes.func,
  handleKeyDownEnter: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  selectOptionsDef: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ),
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      label: PropTypes.string,
    })
  ),
  complex: PropTypes.bool,
  extraWidth: PropTypes.bool,
  tooltipInfo: PropTypes.string
};

export default CustomInput;
