import React from 'react';

import PropTypes from 'prop-types';
import { BtnsContainer, FormContainer } from '../../styles/styled-components/FormBox';
import ButtonFormBoxMenu from '../ButtonFormBoxMenu';

const FormBox = ({ isLastIdx, formBoxData, formBoxDataIdx, children, withOptionIsVisible=false, onClickMenuButton }) => {
  
  const renderVisibleIcon = () => {
    if (withOptionIsVisible) {
      return (
        <>
          {formBoxData.isVisible
            ? <ButtonFormBoxMenu
              icon="visible"
              onClick={() => onClickMenuButton("visible")}
            />
            :
            <ButtonFormBoxMenu
              icon="inVisible"
              onClick={() => onClickMenuButton("inVisible")}
            />
          }
        </>
      );
    }
  };
  
  return (
    <div className="d-flex flex-row gap-4">
      <FormContainer className="d-flex flex-column gap-4" $disabled={formBoxData.isVisible === false}>
        {children}
      </FormContainer>
      <BtnsContainer>
        <ButtonFormBoxMenu
          isDisabled={formBoxDataIdx === 0}
          icon="arrow_up"
          onClick={() => onClickMenuButton("arrowUp")}
        />
        {renderVisibleIcon()}
        <ButtonFormBoxMenu
          icon="minus"
          onClick={() => onClickMenuButton("minus")}
        />
        <ButtonFormBoxMenu
          isDisabled={!!isLastIdx}
          icon="arrow_down"
          onClick={() => onClickMenuButton("arrow_down")}
        />
      </BtnsContainer>
    </div>
  );
};

FormBox.propTypes = {
  isLastIdx: PropTypes.bool,
  formBoxData: PropTypes.object,
  formBoxDataIdx: PropTypes.number,
  children: PropTypes.any,
  withOptionIsVisible: PropTypes.bool,
  onClickMenuButton: PropTypes.func
};

export default FormBox;
