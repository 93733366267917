export const moveElementInArray = (arraySource, idxParameter, direction) => {
  let arrayFirstHalf, arraySecondHalf;
  
  if (direction === "up") {
    arrayFirstHalf = arraySource.slice(0, idxParameter - 1);
    arraySecondHalf = arraySource.slice(idxParameter + 1);
    
    arrayFirstHalf.push(arraySource[idxParameter]);
    arrayFirstHalf.push(arraySource[idxParameter - 1]);
  }
  else {
    arrayFirstHalf = arraySource.slice(0, idxParameter);
    arraySecondHalf = arraySource.slice(idxParameter + 2);
    
    arrayFirstHalf.push(arraySource[idxParameter + 1]);
    arrayFirstHalf.push(arraySource[idxParameter]);
  }
  arrayFirstHalf.push(...arraySecondHalf);
  return arrayFirstHalf;
};
