import { fromJS, List, Map } from 'immutable';

const initialState = Map({
  selectedOption: "",
  properties: Map({ "parallel": List(), "serial": List() }),
  qcgProperties: List(),
  advancedProps: List()
});

export const executionReducer = (state = initialState, action) => {
  let idUpdateElem;
  switch (action.type) {
  case 'SET_SELECTED_DATA_EXECUTION':
    return state
      .set("selectedOption", action.payload.name)
      .setIn([ "properties", action.payload.name ], fromJS(action.payload.properties) );
    
  case 'UPDATE_PROPERTY_EXECUTION':
    idUpdateElem = state.getIn([ "properties", action.payload.selectedOption ]).findKey(property => property.get('name') === action.payload.propName);
    return state.setIn([ "properties", action.payload.selectedOption, idUpdateElem, "value" ], action.payload.propValue);

  case 'SET_INITIAL_EXECUTION_QCG_DATA':
    return state.set("qcgProperties", fromJS(action.payload));
  
  case 'UPDATE_PROPERTY_EXECUTION_QCG_DATA_VALUE':
    idUpdateElem = state.get("qcgProperties").findKey(property => property.get('name') === action.payload.name);
    return state.setIn([ "qcgProperties", idUpdateElem, "value" ], action.payload.value);
    
  case 'UPDATE_PROPERTY_EXECUTION_QCG_DATA_MAX_VALUE':
    idUpdateElem = state.get("qcgProperties").findKey(property => property.get('name') === action.payload.name);
    return state.setIn([ "qcgProperties", idUpdateElem, "validInfo", "max" ], action.payload.value);
    
  case 'SET_INITIAL_ADVANCED_PARALLEL_PROPS':
    return state.set("advancedProps", action.payload);

  case 'UPDATE_PROPERTY_PARALLEL_ADVANCED':
    idUpdateElem = state.get("advancedProps").findKey(property => property.get('name') === action.payload.name);
    return state.setIn([ "advancedProps", idUpdateElem, "value" ], action.payload.value);
   
  case 'RESET_EXECUTION':
    return initialState;  
  default:
    return state;
  }
};
