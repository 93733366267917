import styled from 'styled-components';
import Select from 'react-select';
import { FormGroup, FormFeedback, Input, Label } from 'reactstrap';

const setInputWidth = (props) => {
  if (props.$definded_width) {
    return props.$definded_width + "px";
  }
  
  return `calc(${props.$extrawidth ? '50%' : '25%'} - 24px)`;
};

export const CustomInputContainer = styled(FormGroup)`
  display: flex;
  flex-direction: ${({ $row_line }) => $row_line ? 'row' : 'column'};
  width: ${ ( props ) => setInputWidth(props) };
  //width: ${({ extrawidth }) => `calc(${extrawidth ? '50%' : '25%'} - 24px)`};
  min-width: ${ ( props ) => (props.$definded_width ? props.$definded_width : 200) + "px"};
  
  ${({ $row_line }) => $row_line && `
    width: 100%;
    align-items: center;
    
    label {
      max-width: 230px;
      width: 25%;
      margin-right: 24px;
      margin-bottom: 0;
    }

    div:first-of-type {
      max-width: 516px;
      width: 100%;
    }
  `}
`;

export const ContainerInputSelect = styled.div`
  width: 100%;
  & > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > div > div.react-select__control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const CustomInputLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.font.gray};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 120%;
`;

export const CustomInputSubContainer = styled(FormGroup)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.7rem;

  textarea {
    height: 240px;
  }
`;

export const CustomInputField = styled(Input)`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray.type_600};
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.16),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  border: none;
  height: 2rem;
  width: ${({ extraWidth, checkbox }) => extraWidth ? '200%' : checkbox ? 'calc(100% - 20px - 0.7rem)' : '100%'};

  ::placeholder {
    color: ${props => props.theme.colors.gray.type_300};
  }

  &.is-invalid {
    box-shadow: 0px 0px 0px 4px rgba(210, 7, 36, 0.60), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
  &.invalid:focus {
    box-shadow: 0 0 0 0.2rem ${props => props.theme.colors.font.red};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.type_000};
    color: ${({ theme }) => theme.colors.gray.type_300};
  }  
`;

export const FormErrMsg = styled(FormFeedback)`
  display: block;
  margin-top: 4px;  
  margin-bottom: -16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.font.red};
`;

export const CustomInputCheckboxContainer = styled(Label)`
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
`;

export const CustomInputCheckbox = styled(Input)`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked {
    & ~ span {
      background-color: ${({ theme }) => theme.colors.pink.type_500};

      &:after {
        display: block;
      }
    }
  }

  &:focus {
    & ~ span {
      box-shadow: ${({ theme }) => `0px 0px 0px 5px ${theme.colors.pink.shadow}`};
    }
  }

  &:disabled {
    &:checked ~ span {
      background-color: ${({ theme }) => theme.colors.pink.type_300};
    }

    & ~ span {
      background-color: ${({ theme }) => theme.colors.gray.type_000};
    }
  }

`;

export const CustomInputCheckboxCheckmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.16),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
    
  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 7px;
    height: 12px;
    border: solid ${({ theme }) => theme.colors.white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const CustomInputSelectDefault = styled.select`
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.gray.type_600};
  padding: 6px 12px;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.16),
    0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  border: none;
  height: 2rem;
  width: 100%;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.gray.type_000};
    color: ${({ theme }) => theme.colors.gray.type_300};
  }
`;

export const CustomFileContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 6px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ invalid }) => invalid ?
    "0px 0px 0px 4px rgba(210, 7, 36, 0.60), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"
    :
    "0px 0px 0px 1px rgba(70, 79, 96, 0.16)"};
  padding: 4px 12px;
  width: 100%;
  
  button {
    height: 24px;
    padding: 2px 12px;
    
    .text {
      margin-left: 0;
    }
  }
`;

export const CustomFileSpan = styled.span`
  flex: 1;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis;
  margin-right: 8px;
  font-size: ${({ theme }) => theme.fontSizes.sm};  
  color: ${({ theme, fileExist }) => fileExist ? theme.colors.font.gray : theme.colors.gray.type_300};
  font-weight: ${({ theme, fileExist }) => fileExist ? theme.fontWeights.bold : theme.fontWeights.medium};
`;

export const CustomSelect = styled(Select)`
  width: 100%;
  height: 32px;

  &.is-invalid {
    border-radius: 6px;
    box-shadow: 0px 0px 0px 4px rgba(210, 7, 36, 0.60), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }

  .react-select__control {
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16);
    border-radius: 6px;
    border: none;
    max-height: 32px;
    min-height: 32px;

    &:hover {
      box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
    }

    &.react-select__control--menu-is-open,
    &.react-select__control--is-focused, 
    &:active {
      box-shadow: 0px 0px 0px 4px rgba(219, 90, 154, 0.40), 0px 0px 0px 1px #DB5A9A, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);    
    }
    
    &.react-select__control--menu-is-open {
      z-index: 2;
    }

    &.react-select__control--is-disabled {
      background-color: #F7F9FC;
      box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
    }

    .react-select__single-value--is-disabled {
      color: #868FA0;     
    }

    & > .react-select__value-container {
      padding: 2px 10px;
    }
  }

  .react-select__menu {
    margin-top: -1px;
    padding: 4px;
    padding-bottom: 0;
    
    .react-select__option {
      border-radius: 6px;
      padding: 4px 8px;
      max-height: 32px;
      min-height: 32px;
    }

    .react-select__option:not(:first-child) {
      margin-top: 4px;
    }
  }

  span.react-select__indicator-separator {
    display: none;
  }

  div.react-select__dropdown-indicator {
    padding: 6px 8px;
  }
`;
