import { List, Map } from 'immutable';

import schemaJSON from '../../assets/config/schema/muqsa_json_schema.json';
import schemaJSON_QCG from '../../assets/config/schema/qcg_muqsa_json_schema.json';
import contentJSON from '../../assets/config/data/content.json';

import jsonInfo from '../../assets/config/data/info.json';
import { setLinkDoc } from '../actions/generalReducer';
import { convertTimeToMaxUnit } from './dataUtils';
import { convertBase64ToString } from './formUtils';

export const initGeneralDataFromJsonConfig = (dispatch) => {
  const schemaObj = JSON.parse(JSON.stringify(jsonInfo));
  dispatch(setLinkDoc(schemaObj.link_doc));
  return schemaObj.link_doc;
};

export const getStepFormData = (stepName) => {
  let schemaObj = JSON.parse(JSON.stringify(schemaJSON));
  let result = {}, listDataElem, dataNodePath;
  if (stepName === "Parameters") {
    listDataElem = schemaObj?.definitions["SampledParam"].properties.sampling_space.discriminator.mapping;
  }
  else {
    listDataElem = schemaObj?.definitions[stepName]?.discriminator.mapping;
  }
  
  if (!listDataElem) {
    return;
  }
  
  Object.entries(listDataElem).forEach(item => {
    dataNodePath = item[1].split("/").slice(1);
    result[item[0]] = schemaObj[dataNodePath[0]][dataNodePath[1]];
    
    if (stepName === "Parameters") {
      delete result[item[0]].properties["param_type"];
      result[item[0]].required = result[item[0]].required.filter(propName => propName !== "param_type");
    }
    if (stepName === "Distribution") {
      delete result[item[0]].properties["dist_type"];
      result[item[0]].required = result[item[0]].required.filter(propName => propName !== "dist_type");
    }
    else {
      delete result[item[0]].properties[stepName.toLowerCase() + "_type"];
      result[item[0]].required = result[item[0]].required.filter(propName => propName !== stepName.toLowerCase() + "_type");
    }
  });
  return result;
};

export const convertQCGPropertiesToStoreData = (uploadFileData) => {
  let schemaObj = JSON.parse(JSON.stringify(schemaJSON_QCG));
  let listProps = [];
  let singleProp, locValue;

  let schemaProps = schemaObj?.definitions.QCGmUQSA.properties;
  Object.entries(schemaProps).forEach(property => {
    if (property[0].startsWith("execution_")) {
      singleProp = setSingleProp(property[0], null, property[1].type, (property[1].required ?? false));
      locValue = uploadFileData ? uploadFileData[property[0]] : property[1].default;
      if (singleProp.validInfo.type === "fileModal") {
        if (locValue?.resource_type === "content") {
          locValue.content = convertBase64ToString(locValue.content);
        }
      }
      singleProp.value = locValue;
      
      singleProp.title = property[1].title;
      singleProp.placeholder = property[1].placeholder;
      singleProp.step = property[1].step ?? "";

      if ("exclusiveMinimum" in property[1]) {
        singleProp.validInfo.min = property[1].exclusiveMinimum; //TODO why here was +1
      }
      listProps.push(singleProp);
    }
  });
  return listProps;  
};

const setSingleProp = (name, value, type, isRequired) => {
  let singleProp = {};
  singleProp.name = name;
  singleProp.value = value;
  singleProp.validInfo = {};
  singleProp.validInfo.type = type;
  singleProp.validInfo.required = isRequired;
  return singleProp;
};

export const setExecutionAdvancedParallelProps = () => {
  let listProps = List();
  listProps = listProps.push(Map(setSingleProp("execution_time_type", "auto", "string", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation", 15, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation_unit", "min", "string", false)));
  listProps = listProps.push(Map(setSingleProp("n_samples", 0, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("estimated_time", 0, "integer", false)));
  return listProps;  
};

export const setExecutionAdvancedParallelPropsWithUploadData = (uploadData) => {
  let listProps = List();
  listProps = listProps.push(Map(setSingleProp("execution_time_type", uploadData.execution_time_type, "string", false)));
  
  const singleEvaluationMaxUnit = convertTimeToMaxUnit(uploadData.single_evaluation);
  listProps = listProps.push(Map(setSingleProp("single_evaluation", singleEvaluationMaxUnit.value, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("single_evaluation_unit", singleEvaluationMaxUnit.unit, "string", false)));
  
  listProps = listProps.push(Map(setSingleProp("n_samples", 0, "integer", false)));
  listProps = listProps.push(Map(setSingleProp("estimated_time", 0, "integer", false)));
  return listProps;
};

export const getQCGProperty = (name) => {
  const schemaObj = JSON.parse(JSON.stringify(schemaJSON_QCG));
  let schemaProps = schemaObj?.definitions.QCGmUQSA.properties;
  return schemaProps[name].default;
};

export const convertPropertiesToStoreData = (properties, uploadedData) => {
  let listProps = [];
  let singleProp;
  let listOfDisabledItems = [];
  
  Object.entries(properties).forEach(property => {
    if (property[0].startsWith("disabled_")) {
      if (uploadedData) {
        property[1].default = uploadedData[property[0]];
      }
      listOfDisabledItems.push(property);
    }
    else {
      singleProp = {};
      singleProp.name = property[0];
      singleProp.validInfo = {};
      singleProp.validInfo.type = (property[1].enum ? "select" : property[1].type);
      if (property[1].pattern) {
        singleProp.validInfo.pattern = property[1].pattern;
      }
      
      Object.entries(property[1]).forEach(item => {
        switch (item[0]) {
        case "default":
          if (property[1].enum || property[1].type === "boolean") { //or enum
            singleProp.value = {
              "label": item[1].toString(),
              "value": item[1]
            };
          }
          else {
            singleProp.value = item[1];
          }
          break;
        case "enum":
        case "type":
        case "title":
          break;
        case "anyOf":
          singleProp.validInfo.anyOf = item[1];
          break;
        case "exclusiveMinimum":
          singleProp.validInfo.min = item[1]; //TODO why here was +1
          break;
        case "items":
          singleProp.validInfo[item[0]] = item[1];
          break;
        case "discriminator":
          singleProp.validInfo.type = "fileModal";
          break;
        case "placeholder":
          singleProp.placeholder = item[1];
          break;
        default:
          singleProp[item[0]] = item[1];
          break;
        }
      });
      
      if (uploadedData) {
        let value = uploadedData[property[0]];
        if ([ "boolean", "select" ].includes(singleProp.validInfo.type)) {
          singleProp.value = {
            "label": value.toString(),
            "value": value
          };
        }
        else if (singleProp.validInfo.type === "fileModal" && value) {
          if (value.content) {
            value.content = convertBase64ToString(value.content);
          }
          singleProp.value = value;
        }
        else {
          singleProp.value = value;
        }
      }
      
      listProps.push(singleProp);
    }
  });
  
  if (listOfDisabledItems.length > 0) {
    listOfDisabledItems.forEach(item => {
      const idx = listProps.findIndex(prop => prop.name === item[0].split('disabled_')[1]);
      listProps[idx].validInfo.isDisabled = item[1].default;
    });
  }
  
  return listProps;
};

export const getContentOfStep = (stepName) => {
  const contentData = JSON.parse(JSON.stringify(contentJSON));
  return contentData.steps[stepName];
};
