import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'reactstrap';

import TopBar from './topbar';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey24, StepContent } from '../styles/styled-components/GlobalStyle';
import { convertPropertiesToStoreData, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { getSelectedSetFromObjectEntries, convertAndCheckedValidOfValueToReduxSave, getCurrPropertyValidInfo } from '../utils/formUtils';
import ButtonALink from '../components/ButtonALink';
import { setSelectedDataApp, updatePropertyApp } from '../actions/applicationAction';
import { checkIsFormValid, checkIsPropValid, getErrorValidMsg, isPropertyInInvalidArray } from '../utils/validationFunc';
import { updatePropertyExecutionQcgDataValue } from '../actions/executionActions';
import FileDefineModule from '../components/FileDefineModule';
import LoadingFullscreen from '../components/LoadingFullscreen';

const StepApplication = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileModalElement = useRef();
  const currLang = navigator.language;
  
  const schemaFormData = useRef();
  const contentData = useRef();
  const requiredProps = useRef();
  const arrayApplicationTypes = useRef();
  
  const [ isLoading, setIsLoading ] = useState(true);
  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ showModal, setShowModal ] = useState();

  const selectedApp = useSelector((state) => state.application);
  const selectedAppName = useRef(selectedApp.get('selectedOption'));
  const appsProperties = useSelector((state) => state.application.get('properties')).toJS();
  const selectedAppProps = selectedApp.getIn([ 'properties', selectedAppName.current ]).toJS();
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const qcgExecutionProps = useSelector((state) => state.execution.get('qcgProperties').toJS());
  
  useEffect(() => {
    schemaFormData.current = getStepFormData("Application");
    contentData.current = getContentOfStep("application");
    arrayApplicationTypes.current = getSelectedSetFromObjectEntries(schemaFormData.current);
    requiredProps.current = schemaFormData.current[selectedAppName.current]?.required;
    setIsLoading(false);
  }, []);
  
  const handleTopbarBtnClick  = () => {
    return checkIsFormValid(selectedAppName.current, requiredProps.current, selectedAppProps, setInvalidProps, "application");
  };

  const handleChangeApplicationType = (event) => {
    selectedAppName.current = event.value;
    let currProps = appsProperties[selectedAppName.current];
    if (currProps.length === 0) {
      currProps = convertPropertiesToStoreData(schemaFormData.current[selectedAppName.current].properties);
    }
    dispatch(setSelectedDataApp(selectedAppName.current, currProps));
    requiredProps.current = schemaFormData.current[selectedAppName.current]?.required;
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current)) {
      dispatch(updatePropertyApp(selectedAppName.current, propertyName, value));
    }
  };  

  const handleChangePropValue = (value, propertyName, validInfo) => {
    checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current);
    dispatch(updatePropertyApp(selectedAppName.current, propertyName, value));
  };

  const renderSchemaJsonFormData = () => {
    const formElem = schemaFormData.current[selectedAppName.current].properties;
    let currPropertyRedux, isInvalid;
    return (
      <>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedAppProps.find(property => property.name === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.value, getCurrPropertyValidInfo(item[0], selectedAppProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.current?.includes(item[0])}
            isRowElemDirection={true}
            itemData={item[1]}
            name={item[0]}
            placeholder={item[1].placeholder}
            typeOfInput={currPropertyRedux.validInfo.type}
            value={currPropertyRedux.value}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedAppProps))}
            handleChange={(e) => handleChangePropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedAppProps))}
          />;
        })
        }
      </>
    );
  };
  
  const handleConfirmSaveFileData = (fileData) => {
    if (!fileData[fileData.resource_type]) {
      fileData = null;
    }
    
    dispatch(updatePropertyExecutionQcgDataValue(fileModalElement.current.name, fileData));
    
    if ([ fileModalElement.current ].includes(requiredProps.current)) {
      if (invalidProps.find(property => property === fileModalElement.current.name) && fileData[fileData?.resource_type]) {
        setInvalidProps(invalidProps.filter(item => item !== fileModalElement.current.name));
      }
      if (!invalidProps.find(property => property === fileModalElement.current.name) && !fileData[fileData?.resource_type]) {
        setInvalidProps(data => [ ...data, fileModalElement.current.name ]);
      }
    }
    setShowModal(false);
  };

  const handleChangePropValueQcgParam = (event, propertyName, validInfo) => {
    if (validInfo.type === "fileModal") {
      fileModalElement.current = null;
      fileModalElement.current = { name: propertyName, value: qcgExecutionProps.find(property => property.name === propertyName)?.value };
      setShowModal(true);
    }
    else {
      const value = convertAndCheckedValidOfValueToReduxSave(event, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current);
      dispatch(updatePropertyExecutionQcgDataValue(propertyName, value));
    }
  };

  const handleBlurPropValueQcgParam = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current)) {
      dispatch(updatePropertyExecutionQcgDataValue(propertyName, value));
    }
  };  

  const renderQCGSchemaJsonFormData = () => {
    const data = qcgExecutionProps;
    let isInvalid;
    return (
      <>
        {data.map(item => {
          if (item.step === "application") {
            isInvalid = isPropertyInInvalidArray(item.name, invalidProps);
            return <CustomInput
              key={"key_"+item.name}
              label={item.title}
              invalidMsg={isInvalid ? getErrorValidMsg(item.value, getCurrPropertyValidInfo(item.name, qcgExecutionProps)) : ""}
              isInvalid={isInvalid}
              isRequired={item.validInfo.required}
              isRowElemDirection={true}
              name={item.name}
              placeholder={item.placeholder || t('card.none')}
              typeOfInput={item.validInfo.type}
              value={item.value}
              handleBlur={(e) => handleBlurPropValueQcgParam(e.target.value, item.name, getCurrPropertyValidInfo(item.name, qcgExecutionProps))}
              handleChange={(e) => handleChangePropValueQcgParam(e, item.name, getCurrPropertyValidInfo(item.name, qcgExecutionProps))}
            />;
          }
          return null;
        })}
      </>
    );
  };
  
  if (isLoading) {
    return <LoadingFullscreen/>;
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_application.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_application.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/application/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData.current?.description[currLang]}</Para14>
        <BoxGrey24>
          <Form>
            <CustomInput
              typeOfInput={"select"}
              isRowElemDirection={true}
              isInputDisabled={false}
              isVisible={true}
              name="application_type"
              label={t('step_application.label_type')}
              placeholder=""
              selectOptions={arrayApplicationTypes.current}
              value={arrayApplicationTypes.current?.find(type => type.value === selectedAppName.current)}
              handleChange={(e) => handleChangeApplicationType(e)}
            />
            { selectedAppName.current && renderSchemaJsonFormData() }
            {renderQCGSchemaJsonFormData()}
          </Form>
        </BoxGrey24>
        {showModal
          && <FileDefineModule
            isFileContentModal={false}
            isShowModal={showModal}
            fileData={fileModalElement.current?.value || null}
            onClickCancelModal={()=>setShowModal(false)}
            onClickConfirmModal={(data) => handleConfirmSaveFileData(data)}
          />
        }
      </StepContent>
    </div>
  );
};

export default StepApplication;
