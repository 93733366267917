import i18n from '../../main/utils/i18nConfigProvider';

import { setSelectedDataMethod } from '../actions/methodActions';
import { setSelectedDataDecoder } from '../actions/decoderActions';
import { setTaskName } from '../actions/generalReducer';
import { setSelectedDataApp } from '../actions/applicationAction';
import { setSelectedDataExecution, setInitialExecutionQCGData } from '../actions/executionActions';
import {
  convertPropertiesToStoreData,
  convertQCGPropertiesToStoreData,
  getStepFormData,
  setExecutionAdvancedParallelPropsWithUploadData
} from './jsonSchemaParser';
import { setInitialAdvancedParallelProps } from '../actions/executionActions';
import * as notify from '../../main/utils/notify';
import { initNewDefaultParameter } from '../actions/parametersAction';
import { initNewDefaultEncoder } from '../actions/encoderAction';

const convertDataToProps = (uploadData, stepName, jsonSchema) => {
  const elemTypeProps = jsonSchema[uploadData[stepName + "_type"]].properties;
  return convertPropertiesToStoreData(elemTypeProps, uploadData);
};

export const uploadDataJsonFile = (fileContent, dispatch) => {
  let jsonData, schemaProp;
  
  try {
    jsonData = JSON.parse(fileContent);
  }
  catch (e) {
    notify.error(i18n.t("start_page.task_modal.upload_file_error"));
    notify.error(i18n.t('validation.msg.invalid_json')+e.message);
    return null;
  }
  
  let propsList;

  //qcgparams
  dispatch(setInitialExecutionQCGData(convertQCGPropertiesToStoreData(jsonData)));

  const muqsaData = jsonData.input_muqsa_config;
  if (!muqsaData) {
    return null;
  }

  dispatch(setTaskName(muqsaData.name));

  if (muqsaData.sampled_params) {
    muqsaData.sampled_params.forEach(elem => {
      propsList = { types: [], distributions: [] };
      schemaProp = getStepFormData("Parameters")[elem.sampling_space.param_type].properties;
      propsList.types = convertPropertiesToStoreData(schemaProp, elem.sampling_space);
      
      if (elem.distribution.dist_type !== "none") {
        schemaProp = getStepFormData("Distribution")[elem.distribution.dist_type].properties;
        propsList.distributions = convertPropertiesToStoreData(schemaProp, elem.distribution);
      }
      
      dispatch(initNewDefaultParameter(elem.name, elem.enabled, elem.sampling_space.param_type, elem.distribution.dist_type, propsList));
    });
  }

  if (muqsaData.method) {
    propsList = convertDataToProps(muqsaData.method, "method", getStepFormData("Method"));
    dispatch(setSelectedDataMethod(muqsaData.method.method_type, propsList));
  }

  if (muqsaData.encoder) {
    muqsaData.encoder.forEach(elem => {
      propsList = convertDataToProps(elem, "encoder", getStepFormData("Encoder"));
      dispatch(initNewDefaultEncoder(elem.encoder_type, propsList));
    });
  }

  if (muqsaData.decoder) {
    propsList = convertDataToProps(muqsaData.decoder, "decoder", getStepFormData("Decoder"));
    dispatch(setSelectedDataDecoder(muqsaData.decoder.decoder_type, propsList));
  }

  if (muqsaData.application) {
    propsList = convertDataToProps(muqsaData.application, "application", getStepFormData("Application"));
    dispatch(setSelectedDataApp(muqsaData.application.application_type, propsList));
  }

  if (muqsaData.execution) {
    propsList = convertDataToProps(muqsaData.execution, "execution", getStepFormData("Execution"));
    dispatch(setInitialAdvancedParallelProps(setExecutionAdvancedParallelPropsWithUploadData(muqsaData.execution)));
    dispatch(setSelectedDataExecution(muqsaData.execution.execution_type, propsList));
  }
  return muqsaData.name;
};
