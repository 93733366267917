export const convertTimeToSelectedUnit = (value, unitFrom, unitTo) => {
  let convertValue;
  if (unitFrom === "h") {
    if (unitTo === "min") {
      convertValue = Math.round(value * 60);
    }
    else if (unitTo === "s") {
      convertValue = Math.round(value * 3600);
    }
  }
  else if (unitFrom === "min") {
    if (unitTo === "h") {
      convertValue = Math.round(value / 60);
    }
    else if (unitTo === "s") {
      convertValue = Math.round(value * 60);
    }
  }
  else if (unitFrom === "s") {
    if (unitTo === "h") {
      convertValue = Math.round(value / 3600);
    }
    else if (unitTo === "min") {
      convertValue = Math.round(value / 60);
    }
  }
  if (convertValue === 0) {
    return 1;
  }
  return convertValue;
};

export const convertTimeToSek = (value, selectedUnit) => {
  switch (selectedUnit) {
  case "h":
    return value * 3600;
  case "min":
    return value * 60;
  default:
    return value;
  }
};

export const convertTimeToMaxUnit = (value) => {
  if (value > 3600) {
    return { value: Math.round(value / 3600), unit: "h" };
  }
  else if (value > 60) {
    return { value: Math.round(value / 60), unit: "min" };
  }
  else {
    return { value: value, unit: "s" };
  }
};
