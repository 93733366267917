import { fromJS, List } from 'immutable';
import { moveElementInArray } from '../utils/formBoxShared';

const initialState = List();

export const parametersReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'INIT_NEW_DEFAULT_PARAMETER':
    let locNewElem = {};
    locNewElem.name = { value: action.payload.name, validInfo: { type: "string" } };
    locNewElem.type = {
      selected: action.payload.type,
      [action.payload.type]: action.payload.properties.types
    };
    locNewElem.distribution = {
      selected: action.payload.distribution,
      [action.payload.distribution]: action.payload.properties.distributions
    };
    locNewElem.isVisible = action.payload.isVisible;
    return state.push(fromJS(locNewElem));
  
  case 'SET_NEW_SELECTED_GROUP_DATA':
    return state
      .setIn([ action.payload.idx, action.payload.groupName, "selected" ], action.payload.selectedName)
      .setIn([ action.payload.idx, action.payload.groupName, action.payload.selectedName ], fromJS(action.payload.properties) );
    
  case 'UPDATE_PARAMETER_NAME':
    if (action.payload.validTypeProperty === "isInvalid") {
      return state.setIn([ action.payload.idx, "name", "validInfo", "isInvalid" ], action.payload.newValue);
    }
    else {
      return state.setIn([ action.payload.idx, "name", "value" ], action.payload.newValue);
    }
    
  case 'UPDATE_PARAMETER_GROUP':
    if (action.payload.propertyName === "name") {
      return state.setIn(
        [ action.payload.idx, action.payload.groupName, "selected" ],
        action.payload.newValue);
    }
    else {
      const idUpdateElem = state.getIn([ action.payload.idx, action.payload.groupName, action.payload.groupItemSelected ])
        .findKey(property => property.get("name") === action.payload.propertyName);
      
      if (action.payload.propertyItem === "value") {
        return state.setIn(
          [ action.payload.idx, action.payload.groupName, action.payload.groupItemSelected, idUpdateElem, action.payload.propertyItem ],
          action.payload.newValue);
      }
      if (action.payload.propertyItem === "validInfo" && action.payload.validTypeProperty === "isInvalid") {
        return state.setIn(
          [ action.payload.idx, action.payload.groupName, action.payload.groupItemSelected, idUpdateElem, action.payload.propertyItem,
            action.payload.validTypeProperty ], action.payload.newValue);
      }
      else if (action.payload.propertyItem === "isDisabled") {
        return state.setIn(
          [ action.payload.idx, action.payload.groupName, action.payload.groupItemSelected, idUpdateElem, "validInfo", action.payload.propertyItem ],
          action.payload.newValue);
      }
    }
    break;
    
  case 'MOVE_PARAMETER':
    return fromJS(moveElementInArray(state.toJS(), action.payload.idx, action.payload.direction));
    
  case 'CHANGE_VISIBILITY_OF_PARAMETER':
    return state.setIn([ action.payload.idx, "isVisible" ], action.payload.isVisibility);
      
  case 'DELETE_PARAMETER':
    return state.delete(action.payload.idx);
      
  case 'RESET_PARAMETER':
    return initialState;
      
  default:
    return state;
  }
};
