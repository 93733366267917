import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CustomInput from '../CustomInput';
import FormBox from './FormBox';
import { changedValueToListElem } from '../../utils/formUtils';
import { getErrorValidMsg, validateValueByType } from '../../utils/validationFunc';
import FileDefineModule from '../FileDefineModule';
import { convertPropertiesToStoreData } from '../../utils/jsonSchemaParser';

const FormBoxContentEncoder = ({ arrayEncoderTypes, isLastIdx, encoderData, encoderIdx, schemaFormData,
  onChangeEncoderData, onChangeToNewEncoderType, onClickMenuButton }) => {
  
  const { t } = useTranslation();
  const fileModalElement = useRef();
  const [ showFileUploadModal, setShowFileUploadModal ] = useState(false);
  
  const handleChangeEncoderType = (selectedValue) => {
    if (!encoderData[selectedValue]) { // selected new type
      const props = convertPropertiesToStoreData(schemaFormData[selectedValue].properties);
      onChangeToNewEncoderType(selectedValue, props);
    }
    else {
      onChangeEncoderData({ property: "selected_encoder_type", newValue: selectedValue });
    }
  };
  
  const handleChangeProperty = (elem, value) => {
    if (elem.validInfo.type === "fileModal") {
      fileModalElement.current = elem.name;
      setShowFileUploadModal(true);
    }
    else {
      if (elem.validInfo.isInvalid === true) {
        if (validateValueByType(value, { type: elem.validInfo.type })) {
          onChangeEncoderData({ property: elem.name, newValue: false, propertyType: "isInvalid" });
        }
      }
      
      onChangeEncoderData({ property: elem.name, newValue: value });
    }
  };
  
  const handleConfirmSaveFileData = (fileData) => {
    if (fileData) {
      onChangeEncoderData({ property: fileModalElement.current, newValue: fileData });
      onChangeEncoderData({ property: fileModalElement.current, newValue: (!fileData[fileData.resource_type]), propertyType: "isInvalid" });
    }
    fileModalElement.current = "";
    setShowFileUploadModal(false);
  };
  
  const renderProperties = () => {
    const requiredProps = schemaFormData[encoderData.selected_encoder_type].required;
    return encoderData[encoderData.selected_encoder_type].map(elem => {
      return <CustomInput
        definedWidth={224}
        isInvalid={elem.validInfo.isInvalid}
        invalidMsg={elem.validInfo.isInvalid ? getErrorValidMsg( elem.value, { type: elem.validInfo.type }) : null}
        key={"key_param_"+elem.name}
        label={t('step_encoder.inputs_label.'+elem.name)}
        isRequired={requiredProps?.includes(elem.name)}
        name={elem.name}
        typeOfInput={elem.validInfo.type}
        value={elem.value}
        handleChange={(e) => handleChangeProperty(elem, e.target.value)}
      />;
    });
  };
  
  return (
    <FormBox isLastIdx={isLastIdx} formBoxData={encoderData} formBoxDataIdx={encoderIdx} onClickMenuButton={onClickMenuButton}>
      <div className="d-flex flex-row flex-wrap gap-4 w-100">
        <CustomInput isRowElemDirection={false}
          definedWidth={224}
          name="encoder-type-name"
          label={t('step_encoder.inputs_label.encoder_type')}
          typeOfInput="select"
          selectOptions={arrayEncoderTypes}
          value={changedValueToListElem(encoderData.selected_encoder_type)}
          handleChange={(val) => handleChangeEncoderType(val.value)}
        />
      
        {renderProperties()}
      </div>
      
      {showFileUploadModal
        && <FileDefineModule
          isShowModal={showFileUploadModal}
          fileData={encoderData[encoderData.selected_encoder_type].find(property => property.name === fileModalElement.current).value}
          onClickCancelModal={() => setShowFileUploadModal(false)}
          onClickConfirmModal={(data) => handleConfirmSaveFileData(data)}
        />
      }
    </FormBox>
  );
};

FormBoxContentEncoder.propTypes = {
  arrayEncoderTypes: PropTypes.array,
  isLastIdx: PropTypes.bool,
  encoderData: PropTypes.object,
  encoderIdx: PropTypes.number,
  schemaFormData: PropTypes.object,
  onChangeEncoderData: PropTypes.func,
  onChangeToNewEncoderType: PropTypes.func,
  onClickMenuButton: PropTypes.func
};

export default FormBoxContentEncoder;
