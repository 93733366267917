import styled, { css } from 'styled-components';

export const FormContainer = styled.div`
  border-radius: 12px;
  border: 1px solid #464F6029;
  width: 100%;
  padding: 24px;

  ${props => {
    if (props.$disabled) {
      return css`
        background-color: #F7F9FC;
        
        * {
          opacity: 0.9 !important;
          pointer-events: none;
        }
      `;
    }
  }};
`;

export const BtnsContainer = styled.div`
  width: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;
