import { fromJS, List, Map } from 'immutable';

const initialState = Map({
  selectedOption: "",
  properties: Map({ "preinstalled": List(), "apptainer": List() })
});

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_SELECTED_DATA_APP':
    return state
      .set("selectedOption", action.payload.name)
      .setIn([ "properties", action.payload.name ], fromJS(action.payload.properties) );

  case 'UPDATE_PROPERTY_APP':
    const idUpdateElem = state.getIn([ "properties", action.payload.selectedOption ]).findKey(property => property.get('name') === action.payload.propName);
    return state.setIn([ "properties", action.payload.selectedOption, idUpdateElem, "value" ], action.payload.propValue);
   
  case 'RESET_APPLICATION': 
    return initialState;
  default:
    return state;
  }
};
