import React from 'react';
import PropTypes from 'prop-types';
import { Para14 } from '../styles/styled-components/GlobalFonts';
import { ContainerRowText, TextLabel } from '../styles/styled-components/RowTextTitleValue';

const RowTextTitleValue = ({ className, label, value }) => {
  return (
    <ContainerRowText className={className ?? ""}>
      <TextLabel>{label}</TextLabel>
      <Para14 className='w-100'>{value}</Para14>
    </ContainerRowText>
  );
};

RowTextTitleValue.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default RowTextTitleValue;
