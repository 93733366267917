import styled, { css } from 'styled-components';

const buttonBasic = css`
  padding: 6px 12px;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  border-color: transparent;
  border-radius: 6px;
`;

export const ButtonTextWhiteStyle = styled.button`
  ${ buttonBasic };
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.gray.type_700};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  
  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }  
  
  &:active {
    box-shadow: 0px 0px 0px 4px rgba(104, 113, 130, 0.40), 0px 0px 0px 1px #687182, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
  
  &:disabled {
    background-color: ${props => props.theme.colors.gray.type_000};
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
  }

  & > .text {
    margin-left: 8px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 20px;
    cursor: pointer;
  }
`;

export const ButtonTextPinkStyle = styled.button`
  ${ buttonBasic };
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.pink.type_500};
  box-shadow: 0px 0px 0px 1px #E0197D, 0px 0px 4px 0px rgba(0, 0, 0, 0.08);

  &:hover {
    background-color: ${props => props.theme.colors.pink.type_600};
    box-shadow: 0px 0px 0px 1px #C44585, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }  
  
  &:active {
    background-color: ${props => props.theme.colors.pink.type_500};
    box-shadow: 0px 0px 0px 4px rgba(219, 90, 154, 0.40), 0px 0px 0px 1px #DB5A9A, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
  
  &:disabled {
    background-color: ${props => props.theme.colors.pink.type_300};
    box-shadow: 0px 0px 0px 1px #F59BC8, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const ButtonFormBoxMenuStyle = styled.button`
  width: 32px;
  height: 32px;
  border-color: transparent;
  border-radius: 8px;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.gray.type_700};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

  &:hover {
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:active {
    box-shadow: 0px 0px 0px 4px rgba(219, 90, 154, 0.40), 0px 0px 0px 1px #DB5A9A, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:disabled {
    background-color: ${props => props.theme.colors.gray.type_000};
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
  }

  & > .text {
    margin-left: 8px;
    font-size: ${({ theme }) => theme.fontSizes.sm};
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    line-height: 20px;
    cursor: pointer;
  }
`;

export const ButtonALinkStyle = styled.a`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  line-height: 28px;
  color: ${props => props.theme.colors.pink.type_500};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.pink.type_600};
    text-decoration: none;
  }  
  
  &:active {
    color: ${props => props.theme.colors.pink.type_500};
  }
  
  &:disabled {
    color: ${props => props.theme.colors.pink.type_300};
  }
`;

export const ButtonLinkStyle = styled.button`
  padding: 0;
  background-color: transparent;
  border: none;
`;
