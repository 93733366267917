export const initNewDefaultParameter = (name, isVisible, type, distribution, properties) => {
  return {
    type: 'INIT_NEW_DEFAULT_PARAMETER',
    payload: {
      name,
      isVisible,
      type,
      distribution,
      properties
    }
  };
};

export const setNewSelectedGroupData = (idx, groupName, selectedName, properties) => {
  return {
    type: 'SET_NEW_SELECTED_GROUP_DATA',
    payload: {
      idx, groupName, selectedName, properties
    }
  };
};

export const updateParameterName = (idx, newValue, validTypeProperty) => {
  return {
    type: 'UPDATE_PARAMETER_NAME',
    payload: {
      idx,
      newValue,
      validTypeProperty
    }
  };
};

export const updateParameterGroup = (idx, groupName, groupItemSelected, propertyName, propertyItem, newValue, validTypeProperty) => {
  return {
    type: 'UPDATE_PARAMETER_GROUP',
    payload: {
      idx, groupName, groupItemSelected, propertyName, propertyItem, newValue, validTypeProperty
    }
  };
};

export const moveParameter = (idx, direction) => {
  return {
    type: 'MOVE_PARAMETER',
    payload: {
      idx,
      direction
    }
  };
};

export const changeVisibilityOfParameter = (idx, isVisibility) => {
  return {
    type: 'CHANGE_VISIBILITY_OF_PARAMETER',
    payload: {
      idx,
      isVisibility
    }
  };
};

export const deleteParameter = (idx) => {
  return {
    type: 'DELETE_PARAMETER',
    payload: {
      idx
    }
  };
};

export const resetParameters = {
  type: 'RESET_PARAMETER',
};
