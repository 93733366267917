import React from 'react';
import PropTypes from 'prop-types';

import { ButtonFormBoxMenuStyle } from '../styles/styled-components/Button';
import IconArrowDown from '../../assets/svg/icons/card-actions/arrow_down.svg';
import IconArrowUp from '../../assets/svg/icons/card-actions/arrow_up.svg';
import IconInVisible from '../../assets/svg/icons/card-actions/disabled_eye.svg';
import IconMinus from '../../assets/svg/icons/card-actions/minus.svg';
import IconVisible from '../../assets/svg/icons/card-actions/eye.svg';


const ButtonIconTypes = {
  "arrow_down": { icon: IconArrowDown },
  "arrow_up": { icon: IconArrowUp },
  "inVisible": { icon: IconInVisible },
  "minus": { icon: IconMinus },
  "visible": { icon: IconVisible }
};

const ButtonFormBoxMenu = ({
  className,
  icon,
  isDisabled,
  onClick
}) => {
  
  return (
    <ButtonFormBoxMenuStyle
      className={(className ? className : '')}
      disabled={!!isDisabled}
      type="button"
      onClick={onClick}>
      <img
        alt=""
        className='icon'
        src={ButtonIconTypes[icon].icon}
        width="16px"/>
    </ButtonFormBoxMenuStyle>
  );
};

ButtonFormBoxMenu.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOf([ 'arrow_down', 'arrow_up', 'inVisible', 'minus', 'visible' ]).isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func
};

export default ButtonFormBoxMenu;
