import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import TopBar from './topbar';
import { Para14, Para14Thin, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey20, BoxGrey24, StepContent } from '../styles/styled-components/GlobalStyle';
import CustomRadioButton from '../components/CustomRadioButton';
import ButtonALink from '../components/ButtonALink';
import { convertPropertiesToStoreData, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { convertAndCheckedValidOfValueToReduxSave, getCurrPropertyValidInfo, getSelectedSetFromEnum } from '../utils/formUtils';
import { setSelectedDataMethod, updatePropertyMethod } from '../actions/methodActions';
import { getErrorValidMsg, checkIsPropValid, checkIsFormValid, isPropertyInInvalidArray } from '../utils/validationFunc';
import LoadingFullscreen from '../components/LoadingFullscreen';

const StepMethod = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = navigator.language;
  
  const schemaFormData = useRef();
  const contentData = useRef();
  const requiredProps = useRef();
  
  const [ isLoading, setIsLoading ] = useState(true);
  const [ invalidProps, setInvalidProps ] = useState([]);
  
  const selectedMethod = useSelector((state) => state.method);
  const selectedMethodName = useRef(selectedMethod.get("selectedOption"));
  const selectedMethodProps = selectedMethod.getIn([ 'properties', selectedMethodName.current ]).toJS();
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
 
  const arrayRadio = [
    { id: "mc", label: t('step_method.method_mc.name'), doc: "/monte-carlo/" }, 
    { id: "sc", label: t('step_method.method_sc.name'), doc: "/sc/" },
    { id: "pce", label: t('step_method.method_pce.name'), doc: "/pce/" },
    { id: "basic", label: t('step_method.method_basic.name'), doc: "/introduction/" }, 
    { id: "sweep", label: t('step_method.method_sweep.name'), doc: "/introduction/" }
  ];
  
  useEffect(() => {
    schemaFormData.current = getStepFormData("Method");
    contentData.current = getContentOfStep("method");
    requiredProps.current = schemaFormData.current?.[selectedMethodName.current]?.required;
    setIsLoading(false);
  }, []);
  
  const handleTopbarBtnClick  = () => {
    return checkIsFormValid(selectedMethodName.current, requiredProps.current, selectedMethodProps, setInvalidProps, "method");
  };

  const handleChangeMethod = (event) => {
    selectedMethodName.current = (event.target.id).split('-')[1];
    let currProps = selectedMethod.getIn([ 'properties', selectedMethodName.current ]);
    if (currProps.size === 0) {
      currProps = convertPropertiesToStoreData(schemaFormData.current[selectedMethodName.current].properties);
    }
    dispatch(setSelectedDataMethod(selectedMethodName.current, currProps));
    requiredProps.current = schemaFormData.current?.[selectedMethodName.current]?.required;
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current)) {
      dispatch(updatePropertyMethod(selectedMethodName.current, propertyName, value));
    }
  };  

  const handleChangePropValue = (event, propertyName, validInfo) => {
    const value = convertAndCheckedValidOfValueToReduxSave(event, propertyName, validInfo, invalidProps, setInvalidProps);
    dispatch(updatePropertyMethod(selectedMethodName.current, propertyName, value));
  };

  const renderSchemaJsonFormData = () => {
    const formElem = schemaFormData.current[selectedMethodName.current].properties;
    let currPropertyRedux, isInvalid;
    return (
      <Form>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedMethodProps?.find(property => property.name === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.value, getCurrPropertyValidInfo(item[0], selectedMethodProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.current?.includes(item[0])}
            isRowElemDirection={true}
            itemData={item[1]}
            min={item[1].min || 0}
            name={item[0]}
            //placeholder={item[1].placeholder || t('card.none')}
            placeholder={item[1].placeholder}
            selectOptions={item[1].enum ? getSelectedSetFromEnum(item[1].enum) : []}
            typeOfInput={currPropertyRedux.validInfo.type}
            value={currPropertyRedux.value}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedMethodProps))}
            handleChange={(e) => handleChangePropValue(e, item[0], getCurrPropertyValidInfo(item[0], selectedMethodProps))}
          />;       
        })
        }
      </Form>
    );
  };

  if (isLoading) {
    return <LoadingFullscreen/>;
  }
  
  return (
    <div>
      <Helmet>
        <title>{t('step_method.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick}/>
      <StepContent>
        <TitleH3 className='mt-12'>{t('step_method.title_method_selection')}</TitleH3>
        <Para14 className='mt-2'>{t('step_method.description_selection')}</Para14>
        <hr className='my-12'/>
        <Row className="mb-4">
          <Col>
            {arrayRadio.map(item => {
              return <CustomRadioButton 
                className="mb-4"
                key={item.id}
                id={item.id} 
                label={item.label}
                checked={item.id === selectedMethodName.current}
                onChange={handleChangeMethod}
              />;  
            })}
          </Col>
          { selectedMethodName.current
            && <Col className="ml-2">
              <BoxGrey20>
                <Para14Thin>
                  {contentData.current?.[selectedMethodName.current].description[currLang]}
                </Para14Thin>
              </BoxGrey20>
              <ButtonALink
                className="float-right mt-2"
                href={linkDocumentation + "concepts/methods" + arrayRadio.find(radio => radio.id === selectedMethodName.current).doc}
                icon="documentation"
                text={t('button.go_to_documentation')}            
              />
            </Col>
          }
        </Row>
        { selectedMethodName.current
          && <>
            <hr className='mb-4'/>
            <div>
              <TitleH3>{t('step_method.title_method_parameters')}</TitleH3>
              <Para14 className='mt-2'>{contentData.current?.subtitle_param}</Para14>
              { selectedMethodName.current && <BoxGrey24>{ renderSchemaJsonFormData() }</BoxGrey24> }
              {/* <Para14Thin className='mt-2'>???Wskazwóki do parametrów metody</Para14Thin> */}
            </div>
          </>
        }
      </StepContent>
    </div>
  );
};

export default StepMethod;
