import { createStore, applyMiddleware, compose } from 'redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { __env } from '../../envloader';
import { loginReducer } from '../../oidc-middleware/reducers/loginReducer';
import { getOidcMiddleware } from '../../oidc-middleware/middleware';
import { stepsReducer } from '../../uqsa-ui/reducers/stepsReducer';
import { generalReducer } from '../../uqsa-ui/reducers/generalReducer';
import { methodReducer } from '../../uqsa-ui/reducers/methodReducer';
import { encoderReducer } from '../../uqsa-ui/reducers/encoderReducer';
import { decoderReducer } from '../../uqsa-ui/reducers/decoderReducer';
import { applicationReducer } from '../../uqsa-ui/reducers/applicationReducer';
import { executionReducer } from '../../uqsa-ui/reducers/executionReducer';
import { parametersReducer } from '../../uqsa-ui/reducers/parametersReducer';

const history = createBrowserHistory({ basename: __env.PUBLIC_URL });

const reducers = {
  router: connectRouter(history),
  login: loginReducer,
  toastr: toastrReducer,
  steps: stepsReducer,
  general: generalReducer,
  parameters: parametersReducer,
  method: methodReducer,
  encoder: encoderReducer,
  decoder: decoderReducer,
  application: applicationReducer,
  execution: executionReducer
};

const rootReducer = combineReducers(reducers);

// as found on: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
const composeEnhancers =
	typeof window === 'object' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	  }) : compose;

const configureStore = (initialState, axiosAuthInstance) => {
  let middlewares = [ thunk, getOidcMiddleware(axiosAuthInstance) ];

  if (__env.NODE_ENV === 'development') {
    // related resources for that strange .default below
    // https://github.com/evgenyrodionov/redux-logger/issues/98
    // https://github.com/59naga/babel-plugin-add-module-exports
    // https://github.com/babel/babel/issues/2212

    if (__env.USE_REDUX_LOGGER) {
      const createLogger = require('redux-logger');

      const logger = createLogger({
        stateTransformer: (state) => (JSON.parse(JSON.stringify(state)))
      });
      middlewares = [ ...middlewares, logger ];
    }
  }

  const rootReducerWithRouter = connectRouter(history)(rootReducer);
  const middleware = applyMiddleware(...middlewares, routerMiddleware(history));
  const store = createStore(rootReducerWithRouter, initialState, composeEnhancers(middleware));

  return {
    store,
    history,
  };
};

export default configureStore;
