import { resetApplication, setSelectedDataApp } from '../actions/applicationAction';
import { resetDecoder, setSelectedDataDecoder } from '../actions/decoderActions';
import { setInitialAdvancedParallelProps, setSelectedDataExecution, setInitialExecutionQCGData, resetExecution } from '../actions/executionActions';
import { resetMethod, setSelectedDataMethod } from '../actions/methodActions';
import {
  convertPropertiesToStoreData,
  convertQCGPropertiesToStoreData,
  getStepFormData,
  setExecutionAdvancedParallelProps
} from './jsonSchemaParser';
import { initNewDefaultEncoder, resetEncoder } from '../actions/encoderAction';
import { initNewDefaultParameter, resetParameters } from '../actions/parametersAction';

export const resetStore = (dispatch) => {
  dispatch(resetParameters);
  dispatch(resetMethod);
  dispatch(resetEncoder);
  dispatch(resetDecoder);
  dispatch(resetApplication);
  dispatch(resetExecution);
};

export const initSchemaData = (dispatch) => {
  let schemaFormData, props;
  
  resetStore(dispatch);
  
  //PARAMETER
  schemaFormData = getStepFormData("Parameters");
  props = {};
  props.types = convertPropertiesToStoreData(schemaFormData["float"].properties);
  schemaFormData = getStepFormData("Distribution");
  props.distributions = convertPropertiesToStoreData(schemaFormData["none"].properties);
  dispatch(initNewDefaultParameter("", true, "float", "none", props));
  
  //METHOD
  schemaFormData = getStepFormData("Method");
  props = convertPropertiesToStoreData(schemaFormData["mc"].properties);
  dispatch(setSelectedDataMethod("mc", props));
  
  //ENCODER
  schemaFormData = getStepFormData("Encoder");
  props = convertPropertiesToStoreData(schemaFormData["generic"].properties);
  dispatch(initNewDefaultEncoder("generic", props));
  
  //DECODER
  schemaFormData = getStepFormData("Decoder");
  props = convertPropertiesToStoreData(schemaFormData["csv"].properties);
  dispatch(setSelectedDataDecoder("csv", props));

  //APPLICATION
  schemaFormData = getStepFormData("Application");
  props = convertPropertiesToStoreData(schemaFormData["preinstalled"].properties);
  dispatch(setSelectedDataApp("preinstalled", props));

  //EXECUTION
  schemaFormData = getStepFormData("Execution");
  props = convertPropertiesToStoreData(schemaFormData["parallel"].properties);
  dispatch(setSelectedDataExecution("serial", props));

  props = convertQCGPropertiesToStoreData();
  dispatch(setInitialExecutionQCGData(props));

  props = setExecutionAdvancedParallelProps();
  dispatch(setInitialAdvancedParallelProps(props));
};
