import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import TopBar from './topbar';
import { StepContent } from '../styles/styled-components/GlobalStyle';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import ButtonIconText from '../components/ButtonIconText';
import { changeVisibilityOfParameter, deleteParameter, initNewDefaultParameter, moveParameter, setNewSelectedGroupData,
  updateParameterGroup, updateParameterName } from '../actions/parametersAction';
import FormBoxContentParameter from '../components/formBox/FormBoxContentParameter';
import * as notify from '../../main/utils/notify';
import i18n from '../../main/utils/i18nConfigProvider';
import ButtonALink from '../components/ButtonALink';
import { convertPropertiesToStoreData, getStepFormData } from '../utils/jsonSchemaParser';
import { getSelectedSetFromObjectEntries } from '../utils/formUtils';
import LoadingFullscreen from '../components/LoadingFullscreen';

const StepParameters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const selectParametersData = useRef();
  const schemaFormData = useRef();
  const [ isLoading, setIsLoading ] = useState(true);
  
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const parameters = useSelector((state) => state.parameters);
  
  useEffect(() => {
    schemaFormData.current = {
      type: getStepFormData("Parameters"),
      distribution: getStepFormData("Distribution")
    };
    selectParametersData.current = {
      type: getSelectedSetFromObjectEntries(schemaFormData.current.type),
      distribution: getSelectedSetFromObjectEntries(schemaFormData.current.distribution)
    };
    setIsLoading(false);
  }, []);
  
  const handleTopbarBtnClick  = () => {
    let isSomeInvalidParam = false;
    
    parameters.toJS().forEach((item, idx) => {
      if (item.isVisible) {
        if (item.name.value === "") {
          isSomeInvalidParam = true;
          dispatch(updateParameterName(idx, true, "isInvalid"));
        }
        
        item.type[item.type.selected].forEach(typeProp => {
          if (!typeProp.validInfo.isDisabled && !typeProp.value && !(typeProp.value === 0)) {
            isSomeInvalidParam = true;
            dispatch(updateParameterGroup(idx, "type", item.type.selected, typeProp.name, "validInfo", true, "isInvalid"));
          }
        });
        if (item.distribution[item.distribution.selected]) {
          item.distribution[item.distribution.selected].forEach(distProp => {
            if (!distProp.validInfo.isDisabled && !distProp.value && !(distProp.value === 0)) {
              isSomeInvalidParam = true;
              dispatch(updateParameterGroup(idx, "distribution", item.distribution.selected, distProp.name, "validInfo",true, "isInvalid" ));
            }
          });
        }
      }
    });
    if (isSomeInvalidParam) {
      notify.warning(i18n.t('validation.msg.form_invalid_bad_required_fields'));
    }
    return !isSomeInvalidParam;
  };
  
  const handleAddNewParameter = () => {
    let props = {};
    props.types = convertPropertiesToStoreData(schemaFormData.current.type["float"].properties);
    props.distributions = convertPropertiesToStoreData(schemaFormData.current.distribution["none"].properties);
    dispatch(initNewDefaultParameter("", true, "float", "none", props));
  };
  
  const handleOnChangeParameterData = (updatedData, idx) => {
    if (updatedData.changeType === "paramName") {
      dispatch(updateParameterName(idx, updatedData.newValue, updatedData.validTypeProperty));
    }
    else { //groupItem
      dispatch(updateParameterGroup(idx, updatedData.groupName, updatedData.groupItemSelected, updatedData.propertyName, updatedData.propertyItem,
        updatedData.newValue, updatedData.validTypeProperty));
    }
  };
  
  const handleOnChangeToNewGroupData = (groupName, selectedName, properties, idx) => {
    dispatch(setNewSelectedGroupData(idx, groupName, selectedName, properties));
  };
  
  const handleOnClickMenuButton = (btnAction, idx) => {
    switch (btnAction) {
    case 'arrowUp':
      dispatch(moveParameter(idx, "up"));
      break;
    case 'visible':
      dispatch(changeVisibilityOfParameter(idx, false));
      break;
    case 'inVisible':
      dispatch(changeVisibilityOfParameter(idx, true));
      break;
    case 'minus':
      dispatch(deleteParameter(idx));
      break;
    case 'arrow_down':
      dispatch(moveParameter(idx, "down"));
      break;
    default:
      break;
    }
  };
  
  const renderParametersFormBoxes = () => {
    const parametersSize = parameters.size;
    return parameters.map((paramData, idx) => {
      return (
        <FormBoxContentParameter
          key={"key_param_"+idx}
          parameterData={paramData.toJS()}
          parameterIdx={idx}
          isLastIdx={idx === parametersSize-1}
          schemaFormData={schemaFormData.current}
          selectParametersData={selectParametersData.current}
          onChangeParameterData={(updatedData) => handleOnChangeParameterData(updatedData, idx)}
          onChangeToNewGroupData={(groupName, selectedName, props) => handleOnChangeToNewGroupData(groupName, selectedName, props, idx)}
          onClickMenuButton={(btnAction) => handleOnClickMenuButton(btnAction, idx)}
        />
      );
    });
  };
  
  if (isLoading) {
    return <LoadingFullscreen/>;
  }
  
  return (
    <div>
      <Helmet>
        <title>{t('step_parameters.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mt-12'>{t('step_parameters.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/parameters/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2'>{t('step_parameters.description')}</Para14>
        
        {parameters.size > 0
          && <div className="my-4 d-flex flex-column gap-4">
            {renderParametersFormBoxes()}
          </div>
        }
        
        <ButtonIconText
          className="mt-4"
          icon="add"
          text={t('step_parameters.btn_add_parameter')}
          onClick={() => handleAddNewParameter()}
        />
      </StepContent>
    </div>
    
  );
};

export default StepParameters;
