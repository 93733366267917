import { convertStringToBase64 } from './formUtils';
import { getQCGProperty } from './jsonSchemaParser';
import { convertTimeToSek } from './dataUtils';

const convertReduxStepToResultStep = (stepName, reduxStepData) => {
  let stepResult = {}, locElem = {}, tmpValue;
  if (stepName === "parameters") {
    stepResult = [];
    reduxStepData.forEach(parameter => {
      locElem = {};
      locElem.name = parameter.name.value;
      locElem.sampling_space = {};
      locElem.sampling_space.param_type = parameter.type.selected;
      parameter.type[parameter.type.selected].forEach(item => {
        locElem.sampling_space["disabled_" + item.name] = item.validInfo.isDisabled || false;
        if (!item.validInfo.isDisabled) {
          locElem.sampling_space[item.name] = item.value;
        }
      });
      locElem.distribution = {};
      locElem.distribution.dist_type = parameter.distribution.selected;
      if (parameter.distribution.selected !== "none") {
        parameter.distribution[parameter.distribution.selected].forEach(item => {
          locElem.distribution["disabled_" + item.name] = item.validInfo.isDisabled || false;
          if (!item.validInfo.isDisabled) {
            locElem.distribution[item.name] = item.value;
          }
        });
      }
      locElem.enabled = parameter.isVisible;
      stepResult.push(locElem);
    });
  }
  else if (stepName === "encoder") {
    stepResult = [];
    reduxStepData.forEach(encoder => {
      locElem = {};
      locElem.encoder_type = encoder.selected_encoder_type;
      encoder[encoder.selected_encoder_type].forEach(item => {
        if (item.validInfo.type === "fileModal" && item.value) {
          locElem[item.name] = {};
          locElem[item.name]["resource_type"] = item.value.resource_type;
          if (item.value.resource_type === "content") {
            tmpValue = convertStringToBase64( item.value[item.value.resource_type]);
          }
          else {
            tmpValue = item.value[item.value.resource_type];
          }
          
          locElem[item.name][item.value.resource_type] = tmpValue;
        }
        else {
          locElem[item.name] = item.value;
        }
      });
      stepResult.push(locElem);
    });
  }
  else {
    stepResult[stepName + "_type"] = reduxStepData.selectedOption;
    if (stepName === "execution") {
      let idx = reduxStepData.advancedProps.findIndex(property => property.name === 'execution_time_type');
      stepResult['execution_time_type'] = reduxStepData.advancedProps[idx].value;
      
      const singleEvaluationVal = reduxStepData.advancedProps.find(property => property.name === 'single_evaluation').value;
      const singleEvaluationUnit = reduxStepData.advancedProps.find(property => property.name === 'single_evaluation_unit').value;
      stepResult['single_evaluation'] = convertTimeToSek(singleEvaluationVal, singleEvaluationUnit);
      
      if (reduxStepData.selectedOption === "serial") {
        return stepResult;
      }
    }
    
    (reduxStepData.properties[reduxStepData.selectedOption]).forEach(property => {
      stepResult[property.name] = convertValueToSaveFormat(property.value, property.validInfo);
    });
  }
  return stepResult;
};

export const convertValueToSaveFormat = (value, validInfo ) => {
  if (value) {
    let result = value; //integer, string, number (float)
    if (validInfo.type === "integer") {
      return parseInt(value);
    }
    if ([ "boolean", "select" ].includes(validInfo.type)) { //boolean/select (object)
      return value.value;
    }
    if (validInfo.type === "array") {
      if (value?.length > 0) {
        if (result.includes(',')) {
          value = value.replace(/\s/g, "");
          result = value.split(",");
        }
        else {
          if (value instanceof Array) {
            return value;
          }
          else {
            return [ value ];
          }
        }
      }
      else {
        result = [];
      }
    }
    if (validInfo.type === "fileModal" && value.resource_type === "content") {
      return { resource_type: value.resource_type, content: convertStringToBase64(value.content) };
    }
    if (validInfo.anyOf) { //array[string]/array[array[string]]
      if (value.includes("[")) {
        value = value.replace(/\s/g, "");
        const regex = /(\[[^([\])]+\])/;
        let newArray, arrayValues;
        result = [];
        
        value.split(regex).forEach(item => {
          if (item.includes("[")) {
            newArray = [];
            
            arrayValues = item.substring(1, item.length - 1);
            arrayValues.split(",").forEach(elem => {
              if (elem && elem !== " ") {
                newArray.push(elem);
              }
            });
            result.push(newArray);
          }
          else {
            item.split(",").forEach(elem => {
              if (elem && elem !== " ") {
                result.push(elem);
              }
            });
          }
        });
      }
      else if (value.includes(",")) {
        result = value.split(",");
      }
    }
    return result;
  }
  return null;
};

export const copyValueFromExecutionProperties = (qcgParamName, muqsaParamName, stateDataExecution) => {
  const muqsaElement = stateDataExecution.properties[stateDataExecution.selectedOption].find(property => property.name === muqsaParamName);
  if (muqsaElement) {
    return muqsaElement.value;
  } 
  else {
    return getQCGProperty(qcgParamName);
  }
};

export const generateResultFromRedux = (stateData) => {
  let result;
  let qcgParams = {};
  let tmpValue, currPropName;
  qcgParams.job_name = stateData.general.get("taskName");
  let executionData = stateData.execution.toJS();
  executionData.qcgProperties.forEach(property => {
    tmpValue = "";
    currPropName = property.name;
    if (currPropName === "execution_nodes") {
      tmpValue = copyValueFromExecutionProperties("execution_nodes", "nodes", executionData);
    }
    else if (currPropName === "execution_cores_per_node") {
      tmpValue = copyValueFromExecutionProperties("execution_cores_per_node", "cores", executionData);
    }
    if (!tmpValue) {
      tmpValue = convertValueToSaveFormat(property.value, property.validInfo);
    }
    if (tmpValue) {
      qcgParams[currPropName] = tmpValue;
    }
  });
  
  result = qcgParams;

  let res_muqsa = {};
  res_muqsa.name = stateData.general.get("taskName");
  res_muqsa.version = "122";
  res_muqsa.executed = "false";

  res_muqsa.sampled_params = convertReduxStepToResultStep("parameters", stateData.parameters.toJS());
  res_muqsa.method = convertReduxStepToResultStep("method", stateData.method.toJS());
  res_muqsa.encoder = convertReduxStepToResultStep("encoder", stateData.encoder.toJS());
  res_muqsa.decoder = convertReduxStepToResultStep("decoder", stateData.decoder.toJS());
  res_muqsa.application = convertReduxStepToResultStep("application", stateData.application.toJS());
  res_muqsa.execution = convertReduxStepToResultStep("execution", executionData);
  result.input_muqsa_config = res_muqsa;
  return result;
};
