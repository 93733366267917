import { fromJS, List } from 'immutable';
import { moveElementInArray } from '../utils/formBoxShared';

const initialState = List();

export const encoderReducer = (state = initialState, action) => {
  switch (action.type) {
  case 'INIT_NEW_DEFAULT_ENCODER':
    let locNewElem = {};
    locNewElem.selected_encoder_type = action.payload.name;
    locNewElem[action.payload.name] = action.payload.properties;
    return state.push(fromJS(locNewElem));
    
  case 'SET_NEW_SELECTED_DATA_ENCODER':
    return state
      .setIn([ action.payload.idx, "selected_encoder_type" ], action.payload.name)
      .setIn([ action.payload.idx, action.payload.name ], fromJS(action.payload.properties) );
    
  case 'UPDATE_ELEMENT_OF_ENCODER':
    if (action.payload.property === "selected_encoder_type") {
      return state.setIn([ action.payload.idx, action.payload.property ], action.payload.newValue);
    }
    else if (action.payload.propertyType) {
      if (action.payload.propertyType === "isInvalid") {
        const idUpdateElem = state.getIn([ action.payload.idx, state.getIn([ action.payload.idx, 'selected_encoder_type' ]) ])
          .findKey(property => property.get("name") === action.payload.property);
        
        return state.setIn([ action.payload.idx, state.getIn([ action.payload.idx, 'selected_encoder_type' ]), idUpdateElem, "validInfo", "isInvalid" ],
          action.payload.newValue);
      }
    }
    else {
      const idUpdateElem = state.getIn([ action.payload.idx, state.getIn([ action.payload.idx, 'selected_encoder_type' ]) ])
        .findKey(property => property.get("name") === action.payload.property);
      return state.setIn([ action.payload.idx, state.getIn([ action.payload.idx, 'selected_encoder_type' ]), idUpdateElem, "value" ], action.payload.newValue);
    }
    break;
    
  case 'MOVE_ENCODER':
    return fromJS(moveElementInArray(state.toJS(), action.payload.idx, action.payload.direction));
    
  case 'DELETE_ENCODER':
    return state.delete(action.payload.idx);
    
  case 'RESET_ENCODER':
    return initialState;
    
  default:
    return state;
  }
};
