
export const initNewDefaultEncoder = (name, properties) => {
  return {
    type: 'INIT_NEW_DEFAULT_ENCODER',
    payload: {
      name,
      properties
    }
  };
};

export const setNewSelectedDataEncoder = (idx, name, properties) => {
  return {
    type: 'SET_NEW_SELECTED_DATA_ENCODER',
    payload: {
      idx,
      name,
      properties
    }
  };
};

//updateProperty: name, type, distribution
//newValue
//propertyPath: name, [type, name ], [type, isActive], [distribution, name], [distribution, isActive]
export const updateElementOfEncoder = (idx, property, newValue, propertyType) => {
  return {
    type: 'UPDATE_ELEMENT_OF_ENCODER',
    payload: {
      idx,
      property,
      newValue,
      propertyType
    }
  };
};



export const moveEncoder = (idx, direction) => {
  return {
    type: 'MOVE_ENCODER',
    payload: {
      idx,
      direction
    }
  };
};

export const deleteEncoder = (idx) => {
  return {
    type: 'DELETE_ENCODER',
    payload: {
      idx
    }
  };
};

export const resetEncoder = {
  type: 'RESET_ENCODER',
};
