import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Form } from 'reactstrap';

import TopBar from './topbar';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import { BoxGrey24, StepContent } from '../styles/styled-components/GlobalStyle';
import { convertPropertiesToStoreData, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import CustomInput from '../components/CustomInput';
import { getCurrPropertyValidInfo, getSelectedSetFromObjectEntries } from '../utils/formUtils';
import ButtonALink from '../components/ButtonALink';
import { setSelectedDataDecoder, updatePropertyDecoder } from '../actions/decoderActions';
import { getErrorValidMsg, checkIsPropValid, checkIsFormValid, isPropertyInInvalidArray } from '../utils/validationFunc';
import FileDefineModule from '../components/FileDefineModule';
import LoadingFullscreen from '../components/LoadingFullscreen';

const StepDecoder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = navigator.language;
  
  const schemaFormData = useRef();
  const contentData = useRef();
  const requiredProps = useRef();
  const arrayDecoderTypes = useRef();
  
  const [ invalidProps, setInvalidProps ] = useState([]);
  const [ showFileUploadModal, setShowFileUploadModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  
  const selectedDecoder = useSelector((state) => state.decoder);
  const selectedDecoderName = useRef(selectedDecoder.get("selectedOption"));
  const decodersProperties = useSelector((state) => state.decoder.get('properties').toJS());
  const selectedDecoderProps = selectedDecoder.getIn([ 'properties', selectedDecoderName.current ]).toJS();
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  
  useEffect(() => {
    schemaFormData.current = getStepFormData("Decoder");
    contentData.current = getContentOfStep("decoder");
    arrayDecoderTypes.current = getSelectedSetFromObjectEntries(schemaFormData.current);
    requiredProps.current = schemaFormData.current?.[selectedDecoderName.current]?.required;
    setIsLoading(false);
  }, []);
  
  const handleOnTopbarBtnClick  = () => {
    return checkIsFormValid(selectedDecoderName.current, requiredProps.current, selectedDecoderProps, setInvalidProps, "decoder");
  };

  const handleChangeDecoderType = (event) => {
    selectedDecoderName.current = event.value;
    let currProps = decodersProperties[selectedDecoderName.current];
    if (currProps.length === 0) {
      currProps = convertPropertiesToStoreData(schemaFormData.current[selectedDecoderName.current].properties);
    }
    dispatch(setSelectedDataDecoder(selectedDecoderName.current, currProps));
    requiredProps.current = schemaFormData.current?.[selectedDecoderName.current]?.required;
    setInvalidProps([]);
  };

  const handleBlurPropValue = (value, propertyName, validInfo) => {
    if (checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current)) {
      dispatch(updatePropertyDecoder(selectedDecoderName.current, propertyName, value));
    }
  };  

  const handleChangePropValue = (value, propertyName, validInfo) => {
    if (validInfo.type === "fileModal") {
      setShowFileUploadModal(true);
    }
    else {
      checkIsPropValid(value, propertyName, validInfo, invalidProps, setInvalidProps, requiredProps.current);
      dispatch(updatePropertyDecoder(selectedDecoderName.current, propertyName, value));
    }
  };

  const handleConfirmSaveFileData = (fileData) => {
    dispatch(updatePropertyDecoder(selectedDecoderName.current,"decoder", fileData));
    if (invalidProps.find(property => property === "decoder") && fileData[fileData?.resource_type]) {
      setInvalidProps(invalidProps.filter(item => item !== "decoder"));
    }
    if (!invalidProps.find(property => property === "decoder") && !fileData[fileData?.resource_type]) {
      setInvalidProps(data => [ ...data, "decoder" ]);
    }
    setShowFileUploadModal(false);
  };

  const renderSchemaJsonFormData = () => {
    let formElem = schemaFormData.current[selectedDecoderName.current].properties;
    let currPropertyRedux, isInvalid;
    return (
      <>
        { Object.entries(formElem).map(item => {
          currPropertyRedux = selectedDecoderProps.find(property => property.name === item[0]);
          isInvalid = isPropertyInInvalidArray(item[0], invalidProps);
          return <CustomInput
            key={"key_"+item[0]}
            label={item[1].title}
            invalidMsg={isInvalid ? getErrorValidMsg(currPropertyRedux.value, getCurrPropertyValidInfo(item[0], selectedDecoderProps)) : ""}
            isInvalid={isInvalid}
            isRequired={requiredProps.current?.includes(item[0])}
            isRowElemDirection={true}
            itemData={item[1]}
            name={item[0]}
            placeholder={item[1].placeholder}
            typeOfInput={currPropertyRedux.validInfo.type}
            value={currPropertyRedux.value}
            handleBlur={(e) => handleBlurPropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedDecoderProps))}
            handleChange={(e) => handleChangePropValue(e.target.value, item[0], getCurrPropertyValidInfo(item[0], selectedDecoderProps))}
          />;      
        })
        }
      </>
    );
  };
  
  if (isLoading) {
    return <LoadingFullscreen/>;
  }

  return (
    <div>
      <Helmet>
        <title>{t('step_decoder.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleOnTopbarBtnClick}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_decoder.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/decoders/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData.current?.description[currLang]}</Para14>
        <BoxGrey24>
          <Form>
            <CustomInput
              typeOfInput={"select"}
              isRowElemDirection={true}
              name="decoder_type"
              label={t('step_decoder.label_type')}
              placeholder=""
              selectOptions={arrayDecoderTypes.current}
              value={arrayDecoderTypes.current?.find(type => type.value === selectedDecoderName.current)}
              handleChange={(e) => handleChangeDecoderType(e)}
            />
            { selectedDecoderName.current && renderSchemaJsonFormData() }
          </Form>
        </BoxGrey24>
        {showFileUploadModal
          && <FileDefineModule
            isShowModal={showFileUploadModal}
            fileData={selectedDecoderProps.find(property => property.name === 'decoder')?.value}
            onClickCancelModal={() => setShowFileUploadModal(false)}
            onClickConfirmModal={(data) => handleConfirmSaveFileData(data)}
          />
        }
      </StepContent>
    </div>
  );
};

export default StepDecoder;
