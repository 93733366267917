import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import * as notify from '../../main/utils/notify';
import i18n from '../../main/utils/i18nConfigProvider';
import TopBar from './topbar';
import { StepContent } from '../styles/styled-components/GlobalStyle';
import { Para14, TitleH3 } from '../styles/styled-components/GlobalFonts';
import ButtonIconText from '../components/ButtonIconText';
import { convertPropertiesToStoreData, getContentOfStep, getStepFormData } from '../utils/jsonSchemaParser';
import LoadingFullscreen from '../components/LoadingFullscreen';
import ButtonALink from '../components/ButtonALink';
import { deleteEncoder, initNewDefaultEncoder, moveEncoder, setNewSelectedDataEncoder, updateElementOfEncoder } from '../actions/encoderAction';
import FormBoxContentEncoder from '../components/formBox/FormBoxContentEncoder';
import { getSelectedSetFromObjectEntries } from '../utils/formUtils';

const StepEncoder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currLang = navigator.language;
  
  const arrayEncoderTypes = useRef();
  const contentData = useRef();
  const schemaFormData = useRef();
  const [ isLoading, setIsLoading ] = useState(true);
  
  const linkDocumentation = useSelector((state) => state.general.get("linkDoc"));
  const encoders = useSelector((state) => state.encoder);
  
  useEffect(() => {
    schemaFormData.current = getStepFormData("Encoder");
    contentData.current = getContentOfStep("encoder");
    arrayEncoderTypes.current = getSelectedSetFromObjectEntries(schemaFormData.current);
    setIsLoading(false);
  }, []);
  
  const handleTopbarBtnClick  = () => {
    let isSomeInvalidParam = false, requiredProps;
    encoders.toJS().forEach((item, idx) => {
      requiredProps = schemaFormData.current[item.selected_encoder_type].required;
      Object.entries(item[item.selected_encoder_type]).forEach(property => {
        if (requiredProps.includes(property[1].name)) {
          if (!property[1].value) {
            isSomeInvalidParam = true;
            dispatch(updateElementOfEncoder(idx, property[1].name, true, "isInvalid"));
          }
          else {
            if (property[1].validInfo.type === "fileModal" && property[1].value
                && property[1].value.resource_type && !property[1].value[property[1].value.resource_type]) {
              isSomeInvalidParam = true;
              dispatch(updateElementOfEncoder(idx, property[1].name, true, "isInvalid"));
            }
          }
        }
      });
    });
    
    if (isSomeInvalidParam) {
      notify.warning(i18n.t('validation.msg.form_invalid_bad_required_fields'));
    }
    return !isSomeInvalidParam;
  };
  
  const handleAddNewEncoder = () => {
    const props = convertPropertiesToStoreData(schemaFormData.current["generic"].properties);
    dispatch(initNewDefaultEncoder("generic", props));
  };
  
  const handleOnChangeEncoderData = (updatedData, idx) => {
    dispatch(updateElementOfEncoder(idx, updatedData.property, updatedData.newValue, updatedData.propertyType));
  };
  
  const handleOnChangeToNewEncoderType = (type, props, idx) => {
    dispatch(setNewSelectedDataEncoder(idx, type, props));
  };
  
  const handleOnClickMenuButton = (btnAction, idx) => {
    switch (btnAction) {
    case 'arrowUp':
      dispatch(moveEncoder(idx, "up"));
      break;
    case 'minus':
      dispatch(deleteEncoder(idx));
      break;
    case 'arrow_down':
      dispatch(moveEncoder(idx, "down"));
      break;
    default:
      break;
    }
  };
  
  const renderEncodersFormBoxes = () => {
    const encodersSize = encoders.size;
    return encoders.map((encoderData, idx) => {
      return (
        <FormBoxContentEncoder
          arrayEncoderTypes={arrayEncoderTypes.current}
          key={"key_encoder_"+idx}
          encoderData={encoderData.toJS()}
          encoderIdx={idx}
          isLastIdx={idx === encodersSize-1}
          schemaFormData={schemaFormData.current}
          onChangeEncoderData={(updatedData) => handleOnChangeEncoderData(updatedData, idx)}
          onChangeToNewEncoderType={(type, props) => handleOnChangeToNewEncoderType(type, props, idx)}
          onClickMenuButton={(btnAction) => handleOnClickMenuButton(btnAction, idx)}
        />
      );
    });
  };
  
  if (isLoading) {
    return <LoadingFullscreen/>;
  }
  
  return (
    <div>
      <Helmet>
        <title>{t('step_encoder.helmet')}</title>
      </Helmet>
      <TopBar onClickTopbarBtn={handleTopbarBtnClick}/>
      <StepContent>
        <div className='d-flex align-items-center'>
          <TitleH3 className='mb-0'>{t('step_encoder.title')}</TitleH3>
          <ButtonALink
            href={linkDocumentation + "concepts/encoder/"}
            icon="documentation"
          />
        </div>
        <Para14 className='mt-2 mb-4'>{contentData.current?.description[currLang]}</Para14>
        
        {encoders.size > 0
          && <div className="my-4 d-flex flex-column gap-4">
            {renderEncodersFormBoxes()}
          </div>
        }
        
        <ButtonIconText
          className="mt-4"
          icon="add"
          text={t('step_encoder.btn_add_encoder')}
          onClick={() => handleAddNewEncoder()}
        />
      </StepContent>
    </div>
  );
};

export default StepEncoder;
