import styled, { css } from 'styled-components';

export const TitleH1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.xl2}; //24px
  line-height: 32px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${props => props.theme.colors.gray.type_900};
`;

export const TitleH2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.xl}; //20px
  line-height: 28px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${props => props.theme.colors.gray.type_900};
`;

export const TitleH3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg}; //18px
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${props => props.theme.colors.gray.type_900};
`;

const text14 = css`
  font-size: ${({ theme }) => theme.fontSizes.sm}; //14px
  line-height: 20px;
  color: ${props => props.theme.colors.gray.type_700};
`;

export const Para14 = styled.p`
  ${text14};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Para14Gray = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm}; //14px
  line-height: 20px;
  color: ${props => props.theme.colors.font.gray};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Para14Pink = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm}; //14px
  line-height: 20px;
  color: ${props => props.theme.colors.pink.type_600};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const Para14Thin = styled.p`
  ${text14};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
`;

export const Para14SemiBold600 = styled.p`
  ${text14};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
  color: ${props => props.theme.colors.gray.type_600};
`;

export const Text14Normal600 = styled.span`
  ${text14};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${props => props.theme.colors.pink.type_500};
`;

const text12 = css`
  font-size: ${({ theme }) => theme.fontSizes.xs}; //12px
  line-height: 20px;
  color: ${props => props.theme.colors.gray.type_700};
`;

export const Para12 = styled.p`
  ${text12};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
