import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CustomInput from '../CustomInput';
import FormBox from './FormBox';
import { changedValueToListElem } from '../../utils/formUtils';
import { getErrorValidMsg, validateValueByType } from '../../utils/validationFunc';
import { convertPropertiesToStoreData } from '../../utils/jsonSchemaParser';

const FormBoxContentParameter = ({ isLastIdx, parameterData, parameterIdx, schemaFormData, selectParametersData,
  onChangeParameterData, onChangeToNewGroupData, onClickMenuButton }) => {
  
  const { t } = useTranslation();
  
  const handleChangeParamName = (newValue) => {
    if (parameterData.name.validInfo.isInvalid && !!newValue) {
      if (validateValueByType(newValue, parameterData.name.validInfo)) {
        onChangeParameterData({ changeType: "paramName", propertyName: "name", newValue: false, validTypeProperty: "isInvalid" });
      }
    }
    onChangeParameterData({ changeType: "paramName", propertyName: "name", newValue: newValue });
  };
  
  const handleChangeSelectedGroupElem = (groupName, newValue) => {
    if (parameterData[groupName][newValue]) {
      onChangeParameterData({ changeType: "groupItem", groupName: groupName, propertyName: "name", newValue: newValue });
    }
    else {
      const props = convertPropertiesToStoreData(schemaFormData[groupName][newValue].properties);
      onChangeToNewGroupData(groupName, newValue, props);
    }
  };
  
  const handleChangeGroupElem = (groupName, elem, newValue) => {
    if (elem.validInfo.isInvalid === true) {
      if (validateValueByType(newValue, { type: elem.validInfo.type })) {
        onChangeParameterData({ changeType: "groupItem", groupName: groupName, groupItemSelected: parameterData[groupName].selected,
          propertyName: elem.name, propertyItem: "validInfo", newValue: false, validTypeProperty: "isInvalid" });
      }
    }
    onChangeParameterData({ changeType: "groupItem", groupName: groupName, groupItemSelected: parameterData[groupName].selected,
      propertyName: elem.name, propertyItem: "value", newValue: newValue });
  };
  
  const handleChangeGroupElemCheckbox = (groupName, elem, isChecked) => {
    if (!isChecked && elem.validInfo.isInvalid === true) {
      onChangeParameterData({ changeType: "groupItem", groupName: groupName, groupItemSelected: parameterData[groupName].selected,
        propertyName: elem.name, propertyItem: "validInfo", newValue: false, validTypeProperty: "isInvalid" });
    }
    onChangeParameterData({ changeType: "groupItem", groupName: groupName, groupItemSelected: parameterData[groupName].selected,
      propertyName: elem.name, propertyItem: "isDisabled", newValue: !isChecked });
  };
  
  const renderProperties = (properties, groupName) => {
    const requiredProps = schemaFormData[groupName][parameterData[groupName].selected].required;
    
    if (properties) {
      return properties.map(item => {
        return <CustomInput
          checkbox={true}
          definedWidth={224}
          isInputDisabled={(item.validInfo.isDisabled ? item.validInfo.isDisabled : false)}
          isInvalid={item.validInfo.isInvalid}
          invalidMsg={item.validInfo.isInvalid ? getErrorValidMsg(item.value, { type: item.validInfo.type }) : null}
          key={"key_param_"+item.name}
          label={t('step_parameters.inputs_label.'+item.name)}
          isRequired={requiredProps?.includes(item.name)}
          name={item.name}
          placeholder={item.placeholder}
          typeOfInput={item.validInfo.type}
          value={item.value === 0 ? (item.validInfo.type === "number" ? "0.0" : "0") : item.value}
          handleChange={(e) => handleChangeGroupElem(groupName, item, e.target.value)}
          handleChangeCheckboxState={(e) => handleChangeGroupElemCheckbox(groupName, item, e.target.checked)}
        />;
      });
    }
    return null;
  };
  
  return (
    <FormBox isLastIdx={isLastIdx} formBoxData={parameterData} formBoxDataIdx={parameterIdx} onClickMenuButton={onClickMenuButton} withOptionIsVisible={true}>
      <CustomInput
        extraWidth={true}
        isRowElemDirection={false}
        isInvalid={parameterData.name.validInfo.isInvalid}
        invalidMsg={parameterData.name.validInfo.isInvalid ? getErrorValidMsg(parameterData.name.value, parameterData.name.validInfo) : null}
        name="para-name"
        label={t('step_parameters.inputs_label.parameter_name')}
        typeOfInput="text"
        value={parameterData.name.value}
        handleChange={(e) => handleChangeParamName(e.target.value)}
      />
      <div className="d-flex gap-4 flex-wrap">
        <CustomInput isRowElemDirection={false}
          definedWidth={224}
          name="para-type-name"
          label={t('step_parameters.inputs_label.parameter_type')}
          typeOfInput="select"
          selectOptions={selectParametersData.type}
          value={changedValueToListElem(parameterData.type.selected)}
          handleChange={(val) => handleChangeSelectedGroupElem("type", val.value)}
        />
        {renderProperties(parameterData.type[parameterData.type.selected], "type")}
      </div>
      <div className="d-flex gap-4">
        <CustomInput isRowElemDirection={false}
          definedWidth={224}
          name="para-distribution-name"
          label={t('step_parameters.inputs_label.distribution')}
          typeOfInput="select"
          selectOptions={selectParametersData.distribution}
          value={changedValueToListElem(parameterData.distribution.selected)}
          handleChange={(val) => handleChangeSelectedGroupElem("distribution", val.value)}
        />
        {renderProperties(parameterData.distribution[parameterData.distribution.selected], "distribution")}
      </div>
    </FormBox>
  );
};

FormBoxContentParameter.propTypes = {
  isLastIdx: PropTypes.bool,
  parameterData: PropTypes.object,
  parameterIdx: PropTypes.number,
  schemaFormData: PropTypes.object,
  selectParametersData: PropTypes.object,
  onChangeParameterData: PropTypes.func,
  onChangeToNewGroupData: PropTypes.func,
  onClickMenuButton: PropTypes.func
};

export default FormBoxContentParameter;
